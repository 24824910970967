import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

// https://s3.console.aws.amazon.com/s3/buckets/99rises-docs?region=us-west-2&prefix=legal/&showversions=false
class legal extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Investment Brochures and Legal Agreements"></SEO>
        <div className="LegalPage" align="center">
          <h1 align="center">Legal Documents</h1>
          <h2>99rises Services</h2>
          <Link to="https://99rises-docs.s3-us-west-2.amazonaws.com/legal/99rises+-+Account+Management+Agreement+v1.2.pdf">
            Account Management Agreement
          </Link>
          <br />
          <Link to="https://99rises-docs.s3-us-west-2.amazonaws.com/legal/99rises+-+Disclosures+v1.2.pdf">
            Disclosures
          </Link>
          <br />
          <Link to="https://99rises-docs.s3-us-west-2.amazonaws.com/legal/99rises+-+Wrap+Fee+Brochure+v1.2.pdf">
            Wrap Fee Brochure
          </Link>
          <br />
          <Link to="https://99rises-docs.s3-us-west-2.amazonaws.com/legal/99rises+-+Form+CRS+v1.2.pdf">
            Form CRS
          </Link>
          <br />
          <Link to="https://99rises-docs.s3-us-west-2.amazonaws.com/legal/99rises+-+Fee+Schedule+v1.2.pdf">
            Fee Schedule{" "}
          </Link>
          <br />
          <Link to="https://99rises-docs.s3-us-west-2.amazonaws.com/legal/99rises+-+Solicitation+Agreement+v1.1.pdf">
            Solicitation Agreement
          </Link>
          <br />
          <Link to="https://99rises-docs.s3-us-west-2.amazonaws.com/legal/99rises+-+FAQs+v1.1.pdf">
            FAQs
          </Link>
          <br />
          <h2>IRA Account Agreements</h2>
          <Link to="https://99rises-docs.s3-us-west-2.amazonaws.com/legal/99rises+-+Roth+IRA+Agreement+v1.2.pdf">
            Roth IRA Agreement
          </Link>
          <br />
          <Link to="https://99rises-docs.s3-us-west-2.amazonaws.com/legal/99rises+-+Traditional+IRA+Agreement+v1.2.pdf">
            Traditional IRA Agreement
          </Link>
          <h2>Brokerage Agreements</h2>
          Brokerage Services are provided by{" "}
          <Link href="https://brokerage.tradier.com/" target="_blank">
            Tradier Brokerage Inc.
          </Link>
          <h2>General</h2>
          <Link to="https://99rises-docs.s3-us-west-2.amazonaws.com/legal/99rises+-+Terms+of+Use+v1.2.pdf">
            Terms of Use
          </Link>
          <br />
          <Link to="https://99rises-docs.s3-us-west-2.amazonaws.com/legal/99rises+-+Consumer+Privacy+Policy+v1.2.pdf">
            Privacy Policy
          </Link>
          <br />
        </div>
      </Layout>
    )
  }
}
export default legal
